import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step context select`}</strong>{` -- select the default certificate authority context`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step context select
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step context select`}</strong>{` command sets the default certificate authority context.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Select the default certificate authority context:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step context select alpha-one
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      